<template>
  <main>
    <section id="annual_list">
      <div class="w1300 annual_container">

        <div class="main_title_box">
          <h3 class="main_title txt-bold">年會報名</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <div class="content">
          <ul class="event_list">
            <li v-for="event in eventList" :key="event.id">
              <router-link :to="event.route" class="cards_item">
                <div class="wrap">
                  <div class="col20">
                    <div class="data_wrap">
                      <h4 class="ym">{{ event.date.year }} . {{ event.date.month }}</h4>
                      <h1 class="d">{{ event.date.day }}</h1>
                    </div>
                  </div>
                  <div class="col80">
                    <p class="card_title">{{ event.title }}</p>
                    <p class="small" :class="`type-${event.tag}`">
                      <span class="tag">{{ event.tag }}類</span>
                      <span class="credit">．{{ event.credit }}學分</span>
                    </p>
                    <p class="location ls1">{{ event.location }}</p>
                    <p class="time ls1">{{ event.time }}</p>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>

      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'RegisterAnnualIndex',
  data() {
    return {
      eventList: [
        {
          id: 1,
          route: '/register/annual/info',
          date: {
            year: '2020',
            month: '08',
            day: '21',
          },
          title: '台灣居家醫療醫學會2020年學術研討會暨第二屆年會',
          tag: 'A',
          credit: '25',
          location: '東海大學-外文館 (請由東大路進入)',
          time: '10:00 - 16:00 (6 hours)',
        },
        {
          id: 2,
          route: '/register/annual/info',
          date: {
            year: '2020',
            month: '08',
            day: '21',
          },
          title: '台灣居家醫療醫學會2020年學術研討會暨第二屆年會',
          tag: 'B',
          credit: '25',
          location: '東海大學-外文館 (請由東大路進入)',
          time: '10:00 - 16:00 (6 hours)',
        },
      ],
    };
  },
};
</script>
